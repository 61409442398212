/* eslint-disable import/prefer-default-export */
import { get, patch, post } from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import {
  setUserInfo, setManagerInfo, setUsersInfo, setUsersList,
  setValidatedByUsersInfo,
} from './userInfo'

const getUserInfo = (userId: string) => createApiThunk(
  'userinfo/getUserInfo',
  () => get(`/app-rh/members/${userId}/`),
  setUserInfo,
)

const getUsersList = () => createApiThunk(
  'userinfo/getUsersList',
  () => get('/app-rh/members/'),
  setUsersList,
)

const getManagerInfo = (managerId: string) => createApiThunk(
  'userinfo/getUserInfo',
  () => post('/cerbere/users/simple', [managerId]),
  setManagerInfo,
)

const getUsersInfo = (array: string[]) => createApiThunk(
  'userinfo/getUserInfo',
  () => post('/cerbere/users/simple', array),
  setUsersInfo,
)

const getValidatedByUsersInfo = (array: string[]) => createApiThunk(
  'userinfo/getValidatedByUsersInfo',
  () => post('/cerbere/users/simple', array),
  setValidatedByUsersInfo,
)

const patchTeam = (memberId: string, teamId: string) => createApiThunk(
  'userinfo/patchTeam',
  () => patch(`/app-rh/members/${memberId}/`, { teamId }),
)

export {
  getUserInfo,
  getManagerInfo,
  getUsersInfo,
  getUsersList,
  getValidatedByUsersInfo,
  patchTeam,
}

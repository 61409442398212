import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import {
  Box, Paper,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ButtonStyle } from 'components/button'
import Modal from 'components/modal'
import { useEffect, useState } from 'react'
import { getTeams } from 'reducers/teams/teams.thunk'
import { UserInfo } from 'reducers/userInfo/types'
import { setUserInfo } from 'reducers/userInfo/userInfo'
import {
  getManagerInfo, getUserInfo, patchTeam,
} from 'reducers/userInfo/userInfo.thunk'
import { useAppDispatch, useAppSelector } from 'utils/hooks'
import { readRole } from 'utils/humanRead'
import TeamSelectionForm from './form'
import Input from './input'

export default function Profile() {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user)
  const { userInfo, managerInfo } = useAppSelector(state => state.userinfo)
  const { teams } = useAppSelector(state => state.teams)
  const [selectedTeamId, setSelectedTeamId] = useState<string>(null)
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getUserInfo(user.account.id))
    dispatch(getTeams())
  }, [])

  useEffect(() => {
    if (userInfo && userInfo.teamManagerId) {
      dispatch(getManagerInfo(userInfo.teamManagerId))
    }
  }, [userInfo])

  useEffect(() => {
    setSelectedTeamId(userInfo?.teamId !== undefined ? userInfo.teamId : null)
  }, [userInfo])

  const handleTeamChangeValidation = () => {
    dispatch(patchTeam(userInfo.id, selectedTeamId)).unwrap().then((res : UserInfo) => {
      setShowRemoveModal(false)
      dispatch(setUserInfo(res))
    })
  }

  const handleTeamChangeCancellation = () => {
    setSelectedTeamId(userInfo.teamId)
    setShowRemoveModal(false)
  }

  const handleSelectedTeamIdChange = (teamId: string) => {
    setSelectedTeamId(teamId)
    setShowRemoveModal(true)
  }

  return (
    <div className="page profile">
      <div className="header d-flex flex-column">
        <div className="title">Mon profil</div>
      </div>
      <div className="wrapper mt-24">
        <Box display="flex" flexDirection="row" alignItems="center">
          <AccountCircleIcon sx={{ fontSize: 150 }} style={{ color: 'grey' }} />
          <Paper elevation={0}>
            <Typography
              sx={{ fontSize: 34, fontWeight: 800 }}
            >
              {`${user.account.firstName} ${user.account.lastName}`}
            </Typography>
            <div style={{ textTransform: 'capitalize' }}>
              Rôle :
              {' '}
              {readRole(userInfo?.role)}
            </div>
          </Paper>
        </Box>

        <div className="title mt-24">Informations</div>
        <Grid container spacing={5} columns={11} mb={3}>
          <Grid item xs={3}>
            <Input label="Nom" value={user.account.lastName} endAdornment={false} disabled />
          </Grid>
          <Grid item xs={3}>
            <Input label="Prénom" value={user.account.firstName} endAdornment={false} disabled />
          </Grid>
          <Grid item xs={3}>
            <Input label="Mail" value={user.account.email} endAdornment={false} disabled />
          </Grid>
          {userInfo?.teamManagerId && (
            <>
              <Grid item xs={3}>
                <Input
                  label="Nom du Manager"
                  value={managerInfo ? managerInfo[0]?.lastName : ''}
                  endAdornment
                  disabled
                  role={userInfo?.role}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  label="Prénom du Manager"
                  value={managerInfo ? managerInfo[0]?.firstName : ''}
                  endAdornment
                  disabled
                  role={userInfo?.role}
                />
              </Grid>
            </>
          )}
          {userInfo && (
            <Grid item xs={3}>
              <TeamSelectionForm
                selectedTeamId={selectedTeamId}
                teams={teams}
                handleSelectedTeamIdChange={handleSelectedTeamIdChange}
              />
            </Grid>
          )}
        </Grid>
        <Modal
          title="Changement d'équipe"
          bodyText={"Êtes-vous sûr de vouloir changer d'équipe ?"}
          handleClose={handleTeamChangeCancellation}
          onButtonClick={handleTeamChangeValidation}
          buttonStyle={ButtonStyle.error}
          open={showRemoveModal}
          buttonText="Oui, changer d'équipe"
        />
      </div>
    </div>
  )
}

import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { FormControl, MenuItem, Select } from '@mui/material'
import terms from 'assets/terms'
import { Team } from 'reducers/teams/types'

type Props = {
  selectedTeamId: string
  teams: Team[]
  handleSelectedTeamIdChange: (teamId: string) => void
}

export default function TeamSelectionForm({
  selectedTeamId, teams, handleSelectedTeamIdChange,
}: Props) {
  return (
    <FormControl fullWidth>
      <Typography gutterBottom sx={{ fontSize: 14 }} mb={1}>
        Équipe
      </Typography>
      <Select
        value={selectedTeamId}
        onChange={event => handleSelectedTeamIdChange(event.target.value)}
        displayEmpty
        IconComponent={KeyboardArrowDownIcon}
      >
        <MenuItem value={null}>
          <Typography
            ml={1}
            style={{ fontWeight: 400, color: 'black' }}
          >
            {terms.Page.agenda.none}
          </Typography>
        </MenuItem>
        {teams && teams.map(team => (
          <MenuItem
            key={team.id}
            value={team.id}
          >
            <Typography
              ml={1}
              style={{ fontWeight: 400, color: 'black' }}
            >
              {team.name || terms.Page.agenda.none}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

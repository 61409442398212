/* eslint-disable max-len */
import { styled } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from 'utils'
import SimpleButton, { ButtonStyle } from 'components/button'
import { useEffect, useState } from 'react'
import { getUsersList, getUsersInfo } from 'reducers/userInfo/userInfo.thunk'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Box } from '@mui/material'

const LeavesDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    paddingTop: 0,
  },
  '& .MuiDialogActions-root': {},
}))

type Props = {
  title: string
  bodyText?: string
  handleClose: () => void
  onButtonClick: () => void
  buttonStyle: ButtonStyle
  buttonText: string
  open: boolean
  startIcon?: JSX.Element;
  handleSelect: (event, value) => void
}

export default function ModalSearch({
  title, handleClose, onButtonClick, buttonStyle, open,
  buttonText, startIcon, handleSelect,
}: Props) {
  const dispatch = useAppDispatch()
  const { usersList, usersInfo } = useAppSelector(state => state.userinfo)
  const [teamMembers, setTeamMembers] = useState([])
  const [openAuto, setOpenAuto] = useState(false)

  const loading = open && usersList.length === 0

  useEffect(() => {
    if (openAuto) {
      dispatch(getUsersList())
    }
  }, [openAuto])

  useEffect(() => {
    if (usersList) {
      const usersIdList: string[] = usersList.map(item => item.id)
      dispatch(getUsersInfo(usersIdList))
    }
  }, [usersList])

  useEffect(() => {
    const updatedMyTeamList = usersList.map(item => {
      const matchingItem = usersInfo?.find(secondItem => secondItem.id === item.id)
      if (matchingItem && matchingItem.lastName && matchingItem.firstName) {
        return { ...item, lastName: matchingItem.lastName, firstName: matchingItem.firstName }
      }
      return {
        ...item, lastName: 'Inconnu', firstName: 'Inconnu', teamId: 'Inconnu',
      }
    })
    setTeamMembers(updatedMyTeamList)
  }, [usersInfo])

  return (
    <div>
      <LeavesDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-leaves"
        className="modal-leaves"
        PaperProps={{
          sx: {
            borderRadius: '20px',
            boxShadow: 'none',
            padding: '15px 20px',
            minWidth: 600,
            minHeight: 350,
          },
        }}
      >
        <DialogTitle sx={{ fontSize: 20, fontWeight: 800 }} id="modal-leaves">
          {title}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: 25,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Typography gutterBottom sx={{ fontSize: 14 }} mb={1}>
            Rechercher un membre
          </Typography>
          <Autocomplete
            loading={loading}
            noOptionsText="Pas de resultat"
            loadingText="Chargement..."
            ListboxProps={{ style: { maxHeight: 150 } }}
            fullWidth
            open={openAuto}
            onChange={handleSelect}
            onOpen={() => {
              setOpenAuto(true)
            }}
            onClose={() => {
              setOpenAuto(false)
            }}
            id="combo-box-demo"
            options={teamMembers}
            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionDisabled={option => option.teamId !== null}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                {option.firstName}
                {' '}
                {option.lastName}
                {' '}
                {option?.teamId ? (
                  <Typography sx={{ fontSize: 12, marginLeft: 2 }}>
                    (Déjà membre d‘une équipe)
                  </Typography>
                ) : ''}
              </Box>
            )}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Rechercher"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Link href="#" onClick={() => handleClose()} mr={2} sx={{ color: '#4d32e2' }}>Annuler</Link>
          <SimpleButton
            text={buttonText}
            onClick={onButtonClick}
            sx={{ marginRight: '1rem' }}
            startIcon={startIcon}
            style={buttonStyle}
          />
        </DialogActions>
      </LeavesDialog>
    </div>
  )
}

const defaultProps = {
  bodyText: '',
  startIcon: '',
}

ModalSearch.defaultProps = defaultProps
